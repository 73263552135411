import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServerConfigDto } from '@ay-gosu-party/server-shared';
import Bluebird from 'bluebird';
import { firstValueFrom } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root' })
export class ServerConfig extends ServerConfigDto {
  public constructor(private readonly _httpClient: HttpClient) {
    super();
  }

  public async load() {
    let round = 0;

    while (++round) {
      for (const server of environment.servers) {
        try {
          const url = `${server}/api/config`;
          const observable = this._httpClient.get<ServerConfigDto>(url);
          const config = await firstValueFrom(observable);

          const _that = this as ServerConfigDto;
          Object.assign(_that, config);
          return;
        } catch (error) {
          console.error(error);
        }
      }

      console.error(
        `Failed to fetch config from server. Retry ${round} times.`,
      );

      await Bluebird.delay(3000);
    }
  }
}
