import { HttpClient, provideHttpClient } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  EnvironmentProviders,
  ErrorHandler,
  Provider,
} from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter } from '@angular/router';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import { environment } from '../environments/environment';
import { routes } from './app.routes';
import { CustomErrorHandler } from './backstage/error.handler';
import { materialProviders } from './providers/material';
import { sentryProviders } from './providers/sentry';
import { angularSvgIconConfig } from './providers/svg-icon';
import { ServerConfig } from './server-config';

const providers: Array<Provider | EnvironmentProviders> = [
  provideAnimationsAsync(),
  provideRouter(routes),
  provideHttpClient(),
  provideAngularSvgIcon(angularSvgIconConfig),
  { provide: ErrorHandler, useClass: CustomErrorHandler },
  ...materialProviders,
  ...sentryProviders,
  { provide: 'SERVER_URLS', useValue: environment.servers },

  {
    provide: APP_INITIALIZER,
    useFactory: (config: ServerConfig) => async () => await config.load(),
    deps: [ServerConfig, HttpClient],
    multi: true,
  },
  {
    provide: 'LOCAL_STORAGE_KEY',
    useFactory: (config: ServerConfig) => config.LOCAL_STORAGE_KEY,
    deps: [ServerConfig],
  },
  {
    provide: 'SLOGAN',
    useValue: '註冊 GOSU 帳戶，打造專屬的活動',
  },
];

export const appConfig: ApplicationConfig = {
  providers,
};
