import { HttpClient } from '@angular/common/http';
import { TransferState, makeStateKey } from '@angular/core';
import {
  AngularSvgIconConfig,
  SvgHttpLoader,
  SvgLoader,
} from 'angular-svg-icon';
import { Observable } from 'rxjs';

export function svgLoaderFactory(
  http: HttpClient,
  transferState: TransferState,
) {
  return new SvgBrowserLoader(transferState, http);
}

export class SvgBrowserLoader implements SvgLoader {
  public constructor(
    private _transferState: TransferState,
    private _http: HttpClient,
  ) {}

  public getSvg(url: string): Observable<string> {
    const key = makeStateKey<number>('transfer-svg:' + url);
    const data = this._transferState.get(key, null);
    // First we are looking for the translations in transfer-state, if none found, http load as fallback
    if (data) {
      return new Observable((observer) => {
        observer.next(data.toString());
        observer.complete();
      });
    } else {
      return new SvgHttpLoader(this._http).getSvg(url);
    }
  }
}

export const angularSvgIconConfig: AngularSvgIconConfig = {
  loader: {
    provide: SvgLoader,
    useFactory: svgLoaderFactory,
    deps: [HttpClient, TransferState],
  },
};
