import { APP_INITIALIZER } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from '../../environments/environment';
const { sentry } = environment;

export const sentryProviders =
  sentry.enable && sentry.browserTracing
    ? [
        {
          provide: Sentry.TraceService,
          deps: [Router],
        },
        {
          provide: APP_INITIALIZER,
          useFactory: () => () => {},
          deps: [Sentry.TraceService],
          multi: true,
        },
      ]
    : [];
